<template>
  <page-main
    title="Next, we need to get your address"
    aside="How can you be contacted?"
    nextText="How do you describe yourself?"
    buttonTabIndex="9"
    eventName="nav"
    @nav="nav('/Adjectives')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="addressLine1"
            label="Address (Line 1)"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="addressLine2"
            label="Address (Line 2)"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field v-model="city" label="City" tabindex="3"></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="state"
            label="State"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="zipCode"
            label="Zip Code"
            tabindex="5"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="country"
            label="Country"
            tabindex="6"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="phoneNumber"
            label="Phone Number"
            tabindex="7"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="email"
            label="Email Address"
            tabindex="8"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "Address",
  data: () => ({}),
  components: {},
  mounted() {},
  methods: {
    save() {
      this.computeDisplay();
      this.saveAddress();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("address", ["saveAddress"]),
    computeDisplay() {
      let displayName =
        (this.addressLine1.length > 0 ? this.addressLine1 + " " : "") +
        (this.addressLine2.length > 0 ? this.addressLine2 + " " : "") +
        (this.city.length > 0 ? this.city + " " : "") +
        (this.state.length > 0 ? this.state : "") +
        (this.zipCode.length > 0 ? ", " + this.zipCode : "") +
        (this.country.length > 0 ? ", " + this.country : "") +
        (this.phoneNumber.length > 0 ? " | " + this.phoneNumber : "") +
        (this.email.length > 0 ? " | " + this.email : "");

      this.displayValue = displayName;

      return [displayName];
    },
    borderName(item) {
      if (item?.length > 0) {
        return "item-border";
      } else {
        return "";
      }
    }
  },
  computed: {
    ...mapFields("address", [
      "displayValue",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "country",
      "zipCode",
      "phoneNumber",
      "email"
    ]),
    addressDisplay: function() {
      return this.computeDisplay();
    }
  }
};
</script>
